import { initMobileNav } from './header';
import { initHomeMap } from './home';
import { contactSubmit } from './contact.js';

Element.prototype.switchAttribute = function(attribute, val1, val2) {
	this.setAttribute(attribute, (this.getAttribute(attribute) === val1 ? val2 : val1));
};

initMobileNav();

if(document.querySelector('#home_map')) {
	initHomeMap();
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}

const initReviewsCaro = () => {
	const caroSettings = {
		gutter: 20,
		animationDuration: 1000,
		currentSlide: 0,
		currentPos: 0,
		breakpoints: {
			sm: 320,
			md: 450,
			lg: 750,
		},
		slideCount: {
			lg: 3,
			md: 2,
			sm: 1
		}
	};
	
	const _getCurrentBreakpoint = () => {
		let largest = 'sm';
		for(const [k, p] of Object.entries(caroSettings.breakpoints)) {
			if(window.innerWidth > p) {
				largest = k;
			}
		}
		return largest;
	};

	const cards = document.querySelectorAll('.review_card');
	const caroLeft = document.querySelector('#caro_left');
	const caroRight = document.querySelector('#caro_right');
	
	const updateSlidePositions = () => {
	
		let slideNum = -caroSettings.currentPos;

	
		cards.forEach(i => {
			const width = i.getBoundingClientRect().width;
			i.style.left = `${(width * slideNum) + ( caroSettings.gutter/2 ) + (caroSettings.gutter * slideNum)}px`;
			slideNum++;
		});
	
		document.querySelector('#reviews_caro').removeAttribute('unresolved');
	};
	
	const _slideCaro = startAt => {
		let start;
		let atEnd = false;
		const initialVal = caroSettings.currentSlide;
		if(startAt <= 0) {
			startAt = 0;
		} else {
			caroLeft.removeAttribute('hide');
		}

		if ((cards.length) - startAt <= caroSettings.slideCount[_getCurrentBreakpoint()]) {
			startAt = cards.length - caroSettings.slideCount[_getCurrentBreakpoint()];
			atEnd = true;
		} else {
			caroRight.removeAttribute('hide');
		}

		caroSettings.currentSlide = startAt;
		
		const animateSlides = (ts) => {
			start = start || ts;
			let progress = (ts - start)/caroSettings.animationDuration;
			progress = (1 - Math.pow(1 - progress, 3));
			progress = Math.min(1, progress);
			caroSettings.currentPos = initialVal + (startAt - initialVal) * progress;
			if(progress < 1) {
				requestAnimationFrame(animateSlides);
			} else {
				if(startAt === 0) {
					caroLeft.setAttribute('hide', true);
				}
				if(atEnd) {					
					caroRight.setAttribute('hide', true);
				}
			}
			updateSlidePositions();
		};
		requestAnimationFrame(animateSlides);
	};

	window.slideReviewsLeft = () => {
		_slideCaro(caroSettings.currentSlide - caroSettings.slideCount[_getCurrentBreakpoint()]);
	};

	window.slideReviewsRight = () => {
		_slideCaro(caroSettings.currentSlide + caroSettings.slideCount[_getCurrentBreakpoint()]);
	};

	window.addEventListener('resize', () => {
		_slideCaro(caroSettings.currentSlide);
		updateSlidePositions();
	});

	const theOverlay = document.querySelector('#review_overlay');

	const overlayStars = document.querySelector('#overlay_stars');
	const overlayName = document.querySelector('#overlay_name');
	const overlayText = document.querySelector('#overlay_text');

	document.querySelector('#reviews_caro_outer').addEventListener('click', e => {
		const clickedButton = e.target.closest('.review_read_more button');

		if(clickedButton) {
			const theCard = e.target.closest('.review_card');

			theOverlay.setAttribute('show', true);

			overlayName.innerHTML = theCard.getAttribute('name');
			overlayText.innerHTML = theCard.getAttribute('text');
			overlayStars.innerHTML = '';

			for(let i = 0; i < 5; i++) {
				const starImg = document.createElement('img');
				starImg.src = `/static/images/icons/${(i < theCard.getAttribute('rating'))?'star_full':'star_empty'}.svg`;
				overlayStars.append(starImg);

			}
		}

		if(e.target.closest('#overlay_close')) {
			theOverlay.removeAttribute('show');
		}
	});
	
	updateSlidePositions();
};

if(document.querySelector('#reviews_caro')){
	initReviewsCaro();
}

if(document.getElementById('rates_filter')){
	document.getElementById('rates_filter').addEventListener('click', function(e) {
		if (e.target.matches('button')) {
			const filter = e.target.dataset.size;
			document.querySelector('#rates_filter .active').classList.remove('active');
			e.target.classList.add('active');
			if (filter === 'all') {
				Array.prototype.forEach.call(document.querySelectorAll('#rates_table .unit_row'), function(row) {
					row.classList.add('active');
				});
			} else {
				Array.prototype.forEach.call(document.querySelectorAll('#rates_table .unit_row'), function(row) {
					if (row.dataset.size === filter) {
						row.classList.add('active');
					} else {
						row.classList.remove('active');
					}
				});
			}
			if (!document.querySelector('#rates_table .unit_row.active')) {
				document.getElementById('no_units').classList.add('active');
			} else {
				document.getElementById('no_units').classList.remove('active');
			}
		}
	});
}

if(document.getElementById('review_caro')){
	let viewMoreLinks = document.querySelectorAll('#review_caro .view_more');
	let viewLessLinks = document.querySelectorAll('#review_caro .view_less');
	viewMoreLinks.forEach(viewMoreLink => {
		viewMoreLink.addEventListener('click', function(e) {
			let selectedReview = e.target.closest('div');
			selectedReview.classList.add('show_more');
			document.querySelector('#review_caro .caro_inner').style.height = 'auto';
		});
	});
	viewLessLinks.forEach(viewLessLink => {
		viewLessLink.addEventListener('click', function(e) {
			let selectedReview = e.target.closest('div');
			selectedReview.classList.remove('show_more');
			document.querySelector('#review_caro .caro_inner').style.height = 'inherit';
		});
	});
}